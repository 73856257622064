import React from 'react';
import { useAuth } from '@/context/AuthContext';
import { Button } from '@/components/ui/button';
import { LogIn, LogOut, User, Monitor, Terminal, ExternalLink } from 'lucide-react';

interface AuthButtonProps {
  onClose: () => void;
}

const AuthButton = ({ onClose }: AuthButtonProps) => {
  const { isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <li className="pt-4">
        <Button 
          variant="ghost" 
          className="w-full text-white opacity-70"
          disabled
        >
          <span className="animate-pulse">Loading...</span>
        </Button>
      </li>
    );
  }

  if (isAuthenticated) {
    return (
      <li className="pt-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-white mb-2">
            {user?.picture ? (
              <img 
                src={user.picture} 
                alt={user.name || "User"} 
                className="w-6 h-6 rounded-full" 
              />
            ) : (
              <User size={18} />
            )}
            <span>{user?.name || 'User'}</span>
          </div>
          <Button 
            variant="destructive" 
            className="w-full"
            onClick={() => {
              logout();
              onClose();
            }}
          >
            <LogOut size={16} className="mr-1" />
            Logout
          </Button>
        </div>
      </li>
    );
  }

  return (
    <li className="pt-4">
      <a 
        href="https://mygpt.synthreo.ai" 
        target="_blank" 
        rel="noopener noreferrer"
        className="flex items-center gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
        onClick={onClose}
      >
        <Monitor size={16} className="text-blue-400" />
        <span>ThreoAI Portal</span>
        <ExternalLink size={14} className="ml-auto text-blue-400" />
      </a>
      <a 
        href="https://console.botx.cloud" 
        target="_blank" 
        rel="noopener noreferrer"
        className="flex items-center gap-2 p-3 hover:bg-synthreo-black-dark hover:text-synthreo-blue-medium text-white"
        onClick={onClose}
      >
        <Terminal size={16} className="text-blue-400" />
        <span>ThreoBuilder</span>
        <ExternalLink size={14} className="ml-auto text-blue-400" />
      </a>
    </li>
  );
};

export default AuthButton;
